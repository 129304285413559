// @ts-nocheck

import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";
import { render, unmountComponentAtNode } from "react-dom";

import App from "./App";
import routesObject from "./routesObject";
import routesMapped from "./routesMapped";
import ThirdParty from "./core/ThirdParty";

// This code block is for preventing Google translate crashes.
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        window.console.log("error", "Cannot remove a child from a different parent", child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        window.console.log("error", "Cannot insert before a reference node from a different parent", referenceNode, this);
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

ThirdParty.FullStory.init();
routesMapped.init(routesObject);


const MOUNT_NODE = document.getElementById("root");


const renderApp = () =>
  render(
    <App />,
    MOUNT_NODE
  );
renderApp();
