const en = {
  "dateRange": "Rango de fechas no válido",
  "dateValid": "Fecha invalida",
  "email": "Email no válido",
  "fakeEmail": "Email no válido",
  "max": "El valor máximo es {max}",
  "maxLength": "La longitud máxima es {maxLength}",
  "min": "El valor mínimo es {min}",
  "minLength": "La longitud mínima es {minLength}",
  "notSameAs": "Los campos no coinciden",
  "number": "No es un número",
  "pattern": "Formato incorrecto",
  "required": "Campo obligatorio",
  "sameAs": "Los campos no coinciden",
  "string": "Carácter no válido",
  "url": "Invalid url"
};


export default en;
